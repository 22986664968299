<template>
    <p v-html="title"></p>
    <!--  <p v-html="sldHorLine"></p>-->
    <el-form :model="settingForm" label-width="80px">
        <el-table :show-header="false" :data="tableData" border style="width: 100%">
            <el-table-column prop="date" min-width="200" align="right" label="新消息声音提醒">
            </el-table-column>
            <el-table-column align="left" min-width="700">
                <template #default="scope">
                    <template v-if="scope.row.type == 'text'">
                        <el-form-item label-width="0">
                            <el-switch v-model="settingForm.voice" @change="switchVoice"></el-switch>
                        </el-form-item>
                    </template>
                    <template v-if="scope.row.type == 'btn'">
                        <el-button type="primary" size="small" @click="onSubmit">保存</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </el-form>
</template>
<script>
    import { getCurrentInstance, reactive, onMounted } from 'vue'
    import { ElMessage } from 'element-plus'
    import { useStore } from "vuex";

    export default {
        name: "Setting",
        components: {},
        setup() {
            const { proxy } = getCurrentInstance();
            const store = useStore();
            const title = proxy.$sldLlineRtextAddGoodsAddMargin('#0563FF', '聊天设置', 0, 0, 15);
            const sldHorLine = proxy.$getSldHorLine(1);
            const settingForm = reactive({ voice: false });//表单数据
            const identity = localStorage.identity

            //获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
            const getSetting = () => {
                proxy.$post(`v3/helpdesk/${identity}/sound/setting/isOpen`).then(res => {
                    settingForm.voice = res.data.isOpen ? true : false
                })
            }

            //开关方法
            const switchVoice = (e) => {
                settingForm.voice = e;
            }

            //保存数据
            const onSubmit = () => {
                let params = {
                    isOpen: settingForm.voice ? 1 : 0
                }
                proxy.$post(`v3/helpdesk/${identity}/sound/setting/update`, params).then(res => {
                    ElMessage.success(res.msg)
                    store.commit("updateMsgVoiceSetting", settingForm.voice);
                })
            }



            onMounted(() => {

                getSetting()
            })



            return {
                title,
                sldHorLine,
                settingForm,
                tableData: [{
                    date: '新消息声音提醒',
                    name: 222,
                    type: 'text'
                }, {
                    date: '',
                    name: 222,
                    type: 'btn'
                },],
                onSubmit,
                switchVoice,
                getSetting
            };
        }
    };
</script>
<style lang="scss" scoped>
    .el-form-item {
        margin-bottom: 0;
    }
</style>