<template>
    <div class="common-layout">
        <el-container>
            <el-header height="50px">
                <div class="flex_row_between_center">
                    <div class="flex_row_start_center">
                        <div class="flex_row_center_center img_logo_part">
                            <img class="img_logo" :src="imLogo" alt @click='goHome' />
                        </div>
                        <span class="header_title">{{identity=='seller'?'商户-客服助手':'平台-客服助手'}}</span>
                    </div>
                    <div class="header_right flex_row_end_center">
                        <div @click="pageClose" class="flex_row_start_center" style="cursor: pointer;">
                            <div class="back_img">
                                <img src="@/assets/back.png" alt="">
                            </div>
                            <span class="back_text">返回管理后台</span>
                        </div>

                        <div class="right_tab">
                            <div class="right_tab_vi flex_row_center_center">
                                <span class="store_name" v-if="identity=='seller'">{{storeInfo.data.vendorName}}</span>
                                <span v-else class="store_name">{{decodeURIComponent(adminName)}}</span>
                                <span class="el-icon-caret-bottom"></span>
                            </div>
                            <div class="right_tab_hi flex_row_center_center" @click="loginOut">
                                <span class="el-icon-switch-button"></span>
                                <span class="tab_name">退出登录</span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-container>
                <el-aside width="150px">
                    <router-link :to="{path:'/service'}" class="sld_left_nav_item">
                        <i class="el-icon-postcard icon"></i>
                        <span class="text">{{L['对话平台']}}</span>
                    </router-link>

                    <router-link :to="{path:'/history'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-square icon"></i>
                        <span class="text">{{L['聊天记录']}}</span>
                    </router-link>

                    <router-link :to="{path:'/autoReply'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-dot-round icon"></i>
                        <span class="text">{{L['欢迎语']}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </router-link>
                    <router-link :to="{path:'/quickReply'}" class="sld_left_nav_item">
                        <i class="el-icon-chat-line-round icon"></i>
                        <span class="text">{{L['快捷回复']}}</span>
                    </router-link>
                    <router-link :to="{path:'/casualQues'}" class="sld_left_nav_item">
                        <i class="el-icon-collection icon"></i>
                        <span class="text">{{L['常见问题']}}</span>
                    </router-link>
                    <router-link :to="{path:'/setting'}" class="sld_left_nav_item">
                        <i class="el-icon-set-up icon"></i>
                        <span class="text">{{L['聊天设置']}}</span>
                    </router-link>

                </el-aside>
                <el-container :style="'max-width:'+(clientWidth-150)+'px'" id="elContainer">
                    <el-main style="margin:10px">
                        <router-view></router-view>
                    </el-main>
                    <!--                    <el-footer>Footer</el-footer>-->
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import { ref, reactive, getCurrentInstance, onMounted } from "vue";
    import '@/style/base.scss'
    import { useRouter } from "vue-router";
    import { useStore } from "vuex";
    import { storeUrl } from '@/utils/config'

    export default {
        name: 'Home',
        components: {},
        setup() {
            const store = useStore();
            const { proxy } = getCurrentInstance();
            const isCollapse = ref(false);
            const imLogo = require("@/assets/im_logo.png");
            const router = useRouter();
            const storeInfo = reactive({ data: {} });//店铺基本信息
            const clientWidth = ref(0)
            const identity = localStorage.getItem('identity')
            const adminName = localStorage.adminName
            const handleOpen = (key, keyPath) => {
                console.log(key, keyPath);
            }
            const handleClose = (key, keyPath) => {
                console.log(key, keyPath);
            }
            const L = proxy.$getCurLanguage()
            //退出登录功能
            const loginOut = () => {
                //调用商户的退出登录接口
                let sld_refresh_token = localStorage.getItem('sld_refresh_token') || localStorage.getItem('sld_token');
                proxy.$post(`v3/${identity}Login/oauth/logout`, {
                    grant_type: 'refresh_token',
                    refresh_token: sld_refresh_token,
                }).then(res => {
                    if (res.state == 200) {
                        console.log(res)
                        localStorage.clear();
                        window.location.replace(storeUrl + '/user/login?flag=exit');
                    }
                })

                //清除全部缓存

            }
            const goHome = () => {
                router.push({
                    path: "/service",
                });
            }
            //获取店铺的基本信息
            const getStoreBaseInfo = () => {
                proxy.$get('v3/seller/seller/store/indexStoreInfor').then(res => {
                    if (res.state == 200) {
                        storeInfo.data = res.data;
                        store.commit("updateStoreInfo", res.data);
                    }
                })
            }
            //获取声音的设置
            const getVoiceSetting = () => {
                proxy.$post(`v3/helpdesk/${identity}/sound/setting/isOpen`).then(res => {
                    store.commit("updateMsgVoiceSetting", res.data.isOpen ? true : false);
                })
            }


            const pageClose = () => {
                if (navigator.userAgent.indexOf("MSIE") > 0) {
                    if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
                        window.opener = null;
                        window.close();
                    } else {
                        window.open('', '_top');
                        window.top.close();
                    }
                }
                else if (navigator.userAgent.indexOf("Firefox") > 0) {
                    window.location.href = 'about:blank ';
                } else {
                    window.opener = null;
                    window.open('', '_self', '');
                    window.close();
                }
            }

            // router.beforeEach((to) => {
            //     if (to.name != 'service') {
            //         proxy.$socket.disconnect()
            //     } else {
            //         proxy.$socket.connect()
            //     }
            // })


            const getAdminSetting = () => {
                proxy.$get('v3/system/admin/setting/getSettingList', {
                    str: 'platform_customer_service_name,platform_customer_service_logo'
                }).then(res => {
                    if (res.state == 200) {
                        localStorage.setItem('admin_name', res.data[0].value)
                        localStorage.setItem('admin_logo', res.data[1].imageUrl)
                    }
                })
            }

            onMounted(() => {
                if (localStorage.getItem('identity') == 'seller') {

                    getStoreBaseInfo();
                } else {
                    getAdminSetting()
                }
                getVoiceSetting();
                clientWidth.value = document.body.offsetWidth || document.documentElement.offsetWidth
            })

            return { isCollapse, handleOpen, handleClose, imLogo, goHome, loginOut, storeInfo, clientWidth, L, pageClose, adminName, identity }
        }
    }
</script>
<style lang="scss" scoped>
    .sld_left_nav_item {
        width: 100%;
        display: block;
        color: #fff;
        height: 50px;
        line-height: 50px;
        background: #1D2647;

        .icon {
            color: #fff;
            font-size: 16px;
            margin-right: 10px;
            font-weight: bold;
        }

        .text {
            color: #fff;
            font-size: 14px;
        }

        &.router-link-active {
            background: #fff;

            .icon {
                color: #1D2647;
            }

            .text {
                color: #1D2647;
            }
        }
    }

    .el-header,
    .el-footer {
        background-color: #fff;
    }

    .el-header {
        padding-left: 0 !important;
        padding-right: 10px;

        .img_logo_part {
            width: 150px;
            height: 50px;
            background: #1D2647;

            .img_logo {
                width: 37px;
                cursor: pointer;
            }
        }

        .header_title {
            display: inline-block;
            height: 50px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #114A97;
            line-height: 50px;
            margin-left: 20px;
        }

        .header_right {
            .login_out {
                cursor: pointer;

                &:hover {
                    color: #1c94fa;
                }
            }
        }
    }

    .el-aside {
        color: #fff;
        text-align: center;
        background-color: #1D2647;
        padding-top: 3px;
    }

    .el-main {
        background-color: #fff;
        height: calc(100vh - 70px);
        padding: 15px;
    }

    .el-container {
        background: #f0f2f5;
    }

    .el-menu {
        height: calc(100vh - 50px);
        background-color: #1D2647;
    }

    .el-menu-item,
    .el-menu-item i {
        color: #fff;
    }

    .el-menu-item:hover {
        background-color: #fff;

        .el-menu-item,
        .el-menu-item i {
            color: #1D2647;
        }
    }

    .el-menu-item.is-active {
        color: #1D2647;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 150px;
        min-height: 400px;
    }

    .right_tab {
        position: relative;
        min-width: 132px;
        width: 132px;
        max-width: 132px;

        &:hover {
            .right_tab_hi {
                display: flex;
            }

            .right_tab_vi {


                .store_name,
                .el-icon-caret-bottom {
                    color: #333 !important;

                }

                .store_name {}
            }
        }

        .right_tab_vi {

            height: 50px;
            background: #0E6FD7;
            cursor: pointer;

            padding: 0 10px;

            .store_name {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFEFE;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-icon-caret-bottom {
                font-size: 14px;
                color: #FFFEFE;
                margin-left: 9px;
            }
        }

        .right_tab_hi {
            height: 51px;
            background: #F5F5F5;
            position: absolute;
            top: 50px;
            width: 100%;
            left: 0;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            display: none;

            .tab_name {
                margin-left: 8px;
                margin-top: 2px;
            }

            .el-icon-switch-button {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    .back_img {
        margin-right: 10px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }
    }

    .back_text {
        margin-right: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #A2A7BB;
    }
</style>